import React from 'react';
import styled from 'styled-components';
import { withPage } from "../PageContainer";

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <h1>Merck line@即時客服</h1>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default withPage(HomePage);
